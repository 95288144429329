<!--
  PACKAGE_NAME : src\pages\ai\llm\test-work
  FILE_NAME : list
  AUTHOR : hpmoon
  DATE : 2024-11-13
  DESCRIPTION : 운영관리 > LLM > LLM Tester > 프로젝트 작업 조회
-->
<template>
  <div>
    <div class="page-sub-box">
      <EspDxDataGrid :data-grid="dataGrid" :ref="dataGrid.refName" />
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  import { isEmpty } from "@/plugins/common-lib";

  export default {
    components: { EspDxDataGrid },

    props: {},

    data() {
      return {
        dataGrid: {
          keyExpr: 'id', // 그리드 키값
          refName: 'dataGrid',
          excel: {
            autoFilterEnabled: true,
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSource: [],
          // width:'100%',     // 주석처리시 100%
          // height: '100%', // 주석처리시 100%
          apiActionNm: {},
          scrolling: {
            mode: 'standard',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
          },
          showActionButtons: {
            excel: false,
            select: false,
            update: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_msgContents('LLM.WORD.GO_TO_LIST', { defaultValue: '목록 가기' }),
                  elementAttr: { class: 'btn_XS white light_filled' },
                  width: 80,
                  height: 30,
                  onClick: () => {
                    this.onClickGoToList();
                  },
                },
                location: 'before',
              },
            ],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '테스트 아이디',
              alignment: 'center', // left center right
              dataField: 'id',
              visible: false,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: '테스트 명칭',
              i18n: 'LLM.WORD.TEST_NAME',
              alignment: 'center', // left center right
              dataField: 'name',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowEditing: true,
              allowGrouping: false,
            },
            {
              caption: '상태',
              i18n: 'LLM.WORD.STATUS',
              alignment: 'center', // left center right
              dataField: 'status',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowEditing: true,
              allowGrouping: false,
              lookup: {
                dataSource: [],
                displayExpr: 'codeNm',
                valueExpr: 'codeValue'
              },
            },
            {
              caption: '테스트 취소',
              i18n: 'LLM.WORD.CANCEL_TEST',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              allowEditing: false,
              cellTemplate: (container, options) => {
                if (options.data.status === 'not_started') {
                  let button = new DxButton({
                    propsData: {
                      text: this.$_msgContents('LLM.WORD.CANCEL_TEST', { defaultValue: '테스트 취소' }),
                      elementAttr: { class: 'btn_XS white light_filled trash' },
                      value: options.data,
                      onClick: () => {
                        this.onClickCancelTestWork(options.data.id);
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
            {
              caption: '결과',
              i18n: 'LLM.WORD.RESULT',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              allowEditing: false,
              cellTemplate: (container, options) => {
                if (options.data.status === 'done') {
                  let button = new DxButton({
                    propsData: {
                      text: this.$_msgContents('COMPONENTS.DOWNLOAD', { defaultValue: '다운로드' }),
                      elementAttr: { class: 'btn_XS white light_filled download' },
                      value: options.data,
                      onClick: () => {
                        this.onClickDownloadResult(options.data.id);
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
            {
              caption: '등록일시',
              i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
              alignment: 'center', // left center right
              dataField: 'insert_date',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowEditing: false,
              allowGrouping: false,
              calculateCellValue: data => this.$_commonlib.formatDate(data.insert_date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss'),
            },
            {
              caption: '테스트 종료일시',
              i18n: 'LLM.WORD.TEST_END_DATE_TIME',
              alignment: 'center', // left center right
              dataField: 'end_date',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowEditing: false,
              allowGrouping: false,
              calculateCellValue: data => {
                isEmpty(data.end_date) ? '' : this.$_commonlib.formatDate(data.end_date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
              },
            },
          ],
        },

        project_id: null,
      };
    },

    computed: {},

    methods: {
      /** @description 데이터 조회 메서드 */
      async selectDataList() {
        const payload = {
          actionname: 'LLM_JOB_LIST',
          data: {
            project_id: this.project_id,
          },
          loading: true,
        };
        const res = await this.CALL_LLM_API(payload);
        if (res.status === 200) {
          this.dataGrid.dataSource = res.data;
        } else {
          this.$_Msg(this.$_msgContents('CMN_ERROR'), { icon: 'error' });
        }
      },

      /** @description 목록 가기 클릭 */
      onClickGoToList() {
        this.$router.push('/ai/llm/list');
      },

      /** @description 테스트 취소 클릭 */
      async onClickCancelTestWork(id) {
        if (await this.$_Confirm(this.$_msgContents('LLM.MESSAGE.CANCEL_TEST_ALERT', { defaultValue: '해당 테스트를 취소하시겠습니까?' }))) {
          const payload = {
            actionname: 'LLM_JOB_CANCEL',
            data: {
              id,
            },
            loading: true,
          };
          const res = await this.CALL_LLM_API(payload);
          if (res.status === 200) {
            this.$_Toast(this.$_msgContents('LLM.MESSAGE.SUC_CANCEL', { defaultValue: '정상적으로 취소되었습니다.' }));
            await this.selectDataList();
          } else {
            this.$_Msg(this.$_msgContents('CMN_ERROR'), { icon: 'error' });
          }
        }
      },

      /** @description 작업 결과 다운로드 */
      async onClickDownloadResult(id) {
        const payload = {
          actionname: 'LLM_JOB_RESULT_DOWNLOAD',
          data: {
            jod_id: id,
          },
          loading: true,
        };
        const res = await this.CALL_LLM_API(payload);
        if (res.status === 200) {

        } else {
          this.$_Msg(this.$_msgContents('CMN_ERROR'), { icon: 'error' });
        }
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    created() {
      this.project_id = this.$route.query.id;
      this.selectDataList();
      this.dataGrid.columns[2].lookup.dataSource = this.$_getCode('llm_tester_job_status');
    },

    /** @description 라이프사이클 mounted 시 호출되는 메서드 */
    mounted() {

    },
  };
</script>

<style lang="scss" scoped>
</style>
